// Hannan IP
// export const baseUrl = 'http://192.168.100.75:3100/api/v1';

// Techverticks Admin Panel url
// export const baseUrl = 'http://198.199.78.73:8000/api/v1/';
export const baseUrl: string = 'https://api.techverticks.com/api/v1';

// Khizer IP
// export const baseUrl = 'http://192.168.100.28:8000/api/v1/';
// export const baseUrl = 'http://192.168.100.27:8000/api/v1';

// kh home ip address
// export const baseUrl = 'http://192.168.0.102:8000/api/v1';
